<template>
  <div class="page home-page">
    <sections-elem :content="$store.state.page.content.sections"></sections-elem>
    <div
      class="row events-headline">
        <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm text">
          <div class="wrapper">
            <h1>Die nächsten Veranstaltungen</h1>
            <link-elem v-if="events.length > 0" :link="$store.state.page.content.eventspage.value">
              <button-elem class="is-outline">alle anzeigen</button-elem>
            </link-elem>
          </div>
          <hr />
        </div>
    </div>
    <div
      class="row">
        <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm">
          <events-elem
            :events="events"
            :emptyResult="emptyResult"
            :isLoading="isLoading">
          </events-elem>
        </div>
    </div>
    <div class="row">
      <div class="col is-10 is-12-sm is-offset-1 is-no-offset-sm text">
        <hr />
        <text-elem :text="eventsText"></text-elem>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/services/API'
import EventsElem from '@/components/elements/Events'

export default {
  name: 'home-page',
  components: {
    EventsElem
  },
  data () {
    return {
      head: {},
      events: [],
      emptyResult: null,
      eventsText: null,
      isLoading: true
    }
  },
  created () {
    this.getEventsText()
    this.getEvents()
  },
  methods: {
    getEventsText() {
      API.node(this.$store.state.page.content.eventspage.value.uri)
        .then((response) => {
          this.emptyResult = response.data.content.emptyresult
          this.eventsText = response.data.content.eventstext
        })
        .catch((reason) => {})
    },
    getEvents () {
      var request = {
        order: 'asc',
        limit: 3,
        filter: [ 'dateend.gte.today' ]
      }
      this.isLoading = true
      API.children(this.$store.state.page.content.eventspage.value.uri, request)
        .then((response) => {
          this.head = response.data.head
          this.events = response.data.children
          this.isLoading = false
        })
        .catch((reason) => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="sass">
.home-page
  .is-slogans
    display: flex
    flex-wrap: wrap
    list-style-type: none
    margin: 0 0 (- m(3)) 0
    li
      margin-right: m(2)
      +font('big', 'bold')
      line-height: 1.2
      text-transform: uppercase
      &:first-child
        color: $blue
      &:nth-child(2)
        color: $orange
      &:nth-child(3)
        color: $green
      &:last-child
        margin-right: 0
        color: $purple
    + h1
      margin-top: m(6)
  .events-headline
    margin-top: m(10)
    .wrapper
      display: flex
      align-items: center
      justify-content: space-between
      h1
        display: flex
        align-items: center
        margin-bottom: 0
        +icon("calendar")
        &:before
          margin: 2px m(1) 0 0

+sm
  .home-page
    .is-slogans
      .col
        ul
          li
            +font('medium')

</style>