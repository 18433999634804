<template>
  <div
    :class="{ 'is-loading' : isLoading }"
    class="events-elem">
      <spinner-elem></spinner-elem>
      <div
        v-for="(event, i) in events"
        :key="i"
        class="row event typo">
          <div class="col is-3 is-hidden-xs image">
            <link-elem :link="event.head">
              <image-elem :file="event.content.pageimage.value" size="events.list"></image-elem>
            </link-elem>
          </div>
          <div class="col info">
            <link-elem :link="event.head">
              <p class="eventtype">
                {{ event.content.eventtype.value }}
                <span v-if="bookingSold(event)" class="sold">ausgebucht</span>
              </p>
              <h2>{{ event.content.title.value }}</h2>
              <p v-if="event.content.subheadline.value">{{ event.content.subheadline.value }}</p>
              <p v-if="isSingle(event)" class="datetime">
                <span class="date">{{ event.content.date.value | date_weekday }}</span> {{ event.content.time.value | time }}
              </p>
              <p v-if="isWorkshop(event)" class="datetime">
                <span class="date">{{ event.content.date.value | date_weekday }} bis {{ event.content.dateend.value | date_weekday }}</span>
              </p>
              <p v-if="isCourse(event)" class="datetime">
                <span class="date">{{ event.content.datescount.value }} x {{ weekdays(event.content.weekdays.value) }} {{ event.content.time.value | time }}</span><br />
                1. Termin {{ event.content.date.value | date_weekday }}
              </p>
              <p class="speaker">mit {{ event.content.speaker.value }}</p>
            </link-elem>
            <link-elem :link="event.head">
              <button-elem class="is-small is-outline">Mehr Informationen</button-elem>
            </link-elem>
          </div>
        </div>
      <div
        v-if="showEmptyResult"
        class="empty-result">
          <text-elem :text="emptyResult"></text-elem>
      </div>
  </div>
</template>

<script>

export default {
  name: 'events-elem',
  data () {
    return {}
  },
  props: {
    events: {
      type: Array,
      default: () => {
        return []
      }
    },
    emptyResult: {
      type: Object // the textarea-field
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showEmptyResult () {
      return this.events.length === 0 && !this.isLoading
    }
  },
  methods: {
    isSingle (event) {
      return event.head.blueprint === 'event_single'
    },
    isWorkshop (event) {
      return event.head.blueprint === 'event_workshop'
    },
    isCourse (event) {
      return event.head.blueprint === 'event_course'
    },
    bookingSold (event) {
      return event.content.booking.value === 'sold'
    },
    weekdays (list) {
      return list.join(', ')
    }
  }
}
</script>

<style lang="sass">
.events-elem
  position: relative
  .event
    margin-bottom: m(3)
    transition: opacity 1s ease
    &:last-child
      margin-bottom: 0
    .info
      padding-left: col-gap('lg')
      .eventtype
        margin-bottom: m(1)
        text-transform: uppercase
        +font('bold', 'small')
        .sold
          margin-left: m(1)
          color: $warning-color
      .datetime
        margin-top: m(1)
        .date
          +font('medium')
      .speaker
        margin-top: m(1)
        width: 80%
      .button
        margin-top: m(3)
    .image
      img
        margin-top: 4px
  .empty-result
    margin: m(5) 0
    +font('italic')
    color: $grey-light
    text-align: center
  .spinner-elem
    display: none
    top: m(3)
    left: 50%
    transform: translateX(-50%)
  &.is-loading
    .event
      opacity: 0
    .spinner-elem
      display: block

+md
  .events-page
    .event
      .info
        padding-left: col-gap('md')

+sm
  .events-page
    .event
      .info
        padding-left: col-gap('sm')

+xs
  .events-page
    .event
      .info
        padding-left: col-gap('xs') / 2
</style>